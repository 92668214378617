import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "../axiosConfig";

const HomeEmployee = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    axios
      .get("https://api.comnieuthienly.vn/api/form-cv")
      .then((response) => {
        setData(response?.data?.data?.description?.description);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
        setError("Có lỗi xảy ra khi lấy dữ liệu.");
        setLoading(false);
      });
  }, []);

  return (
    <div className="container mx-auto">
      <div
        className="flex flex-col items-center justify-between"
        style={{ height: "100vh" }}
      >
        <div>
          <div className="banner-img card-banner"></div>
          <div className="wrap-body">
            <div className="line"></div>
            <div className="text">
              <span className="text-heading">
                CUNG CẤP THÔNG TIN NHÂN VIÊN MỚI
              </span>
              <p style={{ marginTop: "20px" }}>
                Cơm Niêu Thiên Lý rất vui được chào đón thành viên mới.
                <br></br>
                Phía trước là một hành trình sự nghiệp trong giai đoạn mới của
                chính bạn,{" "}tại đây chúng tôi luôn trân trọng những gì bạn đóng
                góp và cống hiến cho công ty,{" "} bằng những lộ trình đào tạo và
                thăng tiến sự nghiệp của các bạn trong tương lai. <br></br>Hãy
                cùng chúng tôi tạo ra giá trị mang lại hạnh phúc cho khách hàng
                trong những buổi cơm gia đình ấm cúng.
              </p>
              <div className="line-bottom"></div>
              <p>
                Các thông tin bạn cung cấp làm cơ sở để lưu trữ dữ liệu nhân sự, do đó, hãy nhập chính xác nhé.
              </p>
              <p style={{ marginTop: "25px" }}>
                <b>* Lưu ý:</b>{" "}Nếu bạn chưa điền hoàn tất hoặc chưa nhấn Gửi, đừng tắt
                trang này nhé,{" "} nếu không thông tin bị mất và bạn sẽ phải điền
                lại từ đầu đấy.
              </p>
            </div>
            {/* <div dangerouslySetInnerHTML={{ __html: data }} /> */}
            <div className="flex flex-row justify-start items-center">
              <button style={{ margin: "36px 0 15px 0" }}>
                <Link to={"/danh-muc-ho-so-nhan-vien"}>Next</Link>
              </button>
              {/* {loading && <div class="loader"></div>}
            {error && <div id="errorPopup" className="error-popup">{error}</div>} */}
            </div>
          </div>
        </div>
        <a
          className="style-link flex flex-row items-center justify-center"
          href="https://comnieuthienly.vn/"
          target="_blank"
          rel="noopener noreferrer"
        >
           <img
            style={{
              aspectRatio: "auto 74 / 24",
              width: "200px",
            }}
            src="https://i.imgur.com/nB8LOLM.png"
            alt="img-logo-thien-ly"
          />
        </a>
      </div>
    </div>
  );
};

export default HomeEmployee;
