import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "../axiosConfig";
import { toast } from "react-toastify";

const ProvideInfoEmployee = () => {
  const [positions, setPositions] = useState([]);
  const [areas, setAreas] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedWard, setSelectedWard] = useState("");
  const [detailedAddress, setDetailedAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const [provinces2, setProvinces2] = useState([]);
  const [districts2, setDistricts2] = useState([]);
  const [wards2, setWards2] = useState([]);
  const [selectedProvince2, setSelectedProvince2] = useState("");
  const [selectedDistrict2, setSelectedDistrict2] = useState("");
  const [selectedWard2, setSelectedWard2] = useState("");
  const [detailedAddress2, setDetailedAddress2] = useState("");

  const navigate = useNavigate();
  const { id } = useParams();

  const formatDateVN = (dateTime) => {
    if (!dateTime) return ""; // Kiểm tra nếu không có giá trị, trả về chuỗi rỗng
    return dateTime.split(" ")[0]; // Tách chuỗi tại khoảng trắng và lấy phần đầu tiên (YYYY-MM-DD)
  };
  const [formData, setFormData] = useState({
    application_date: new Date().toLocaleDateString("en-CA", {
      timeZone: "Asia/Ho_Chi_Minh",
    }),
  });
  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle radio button changes
  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // handle form field address changes
  const handleAddressChange = (e) => {
    setDetailedAddress(e.target.value);
  };
  // submit
  const handleSubmit = (e) => {
    e.preventDefault();

    if (loading == true) {
      return;
    }

    if ((formData.cmnd_front ?? "") == "") {
      toast.error("Vui lòng tải ảnh mặt trước CCCD/CMND.");
      return;
    }

    if ((formData.cmnd_back_side ?? "") == "") {
      toast.error("Vui lòng tải ảnh mặt sau CCCD/CMND.");
      return;
    }

    setLoading(true);

    const dataSend = {
      name: formData.name,
      birthday: formData.birthday,
      position: formData.position,
      email: formData.email,
      phone: formData.phone,
      sex: formData.sex,
      shift_id: formData.shift_id,
      area_id: formData.area_id,
      start: formData.start,
      mst: formData.mst,
      cmnd: formData.cmnd,
      cccd: formData.cccd,
      date_range: formData.date_range,
      cmnd_front: formData.cmnd_front,
      cmnd_back_side: formData.cmnd_back_side,
      temporary_address:
        detailedAddress == ""
          ? formData.temporary_address
          : `${detailedAddress}, ${
              wards.find((ward) => ward.id == selectedWard).name
            }, ${
              districts.find((district) => district.id == selectedDistrict).name
            }, ${
              provinces.find((province) => province.id == selectedProvince).name
            }`,
      permanent_address:
        detailedAddress2 == ""
          ? formData.permanent_address
          : `${detailedAddress2}, ${
              wards2.find((ward) => ward.id == selectedWard2).name
            }, ${
              districts2.find((district) => district.id == selectedDistrict2)
                .name
            }, ${
              provinces2.find((province) => province.id == selectedProvince2)
                .name
            }`,
      issued_by: formData.issued_by,
      recuitment_position_id: formData.recuitment_position_id,
      bank_name: formData.bank_name,
      bank_number: formData.bank_number,
      bank_account_name: formData.bank_account_name,
    };

    axios
      .put(`https://api.comnieuthienly.vn/api/finish-cv/${id}`, dataSend)
      .then((response) => {
        setLoading(false);
        setTimeout(() => setError(null), 90000);
        // Redirect to result page
        navigate("/done-success");
        console.log("Response:", response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
        setError("Có lỗi xảy ra khi gửi dữ liệu.");
        setTimeout(() => setError(null), 3000); // Hide error message after 3 seconds
      });

    setLoading(false);
  };

  // Fetch Tỉnh/Thành
  useEffect(() => {
    setLoading(true);
    axios
      .get("https://main.doapp.vn/api/place/vn/province")
      .then((response) => {
        const provinces = response?.data?.data || [];
        provinces.sort((a, b) => {
          if (a.id === 1) return -1; // Hà Nội first
          if (b.id === 1) return 1;
          if (a.id === 79) return -1; // Hồ Chí Minh second
          if (b.id === 79) return 1;
          return 0;
        });
        setProvinces(provinces);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching provinces:", error);
        setError("Có lỗi xảy ra khi lấy danh sách tỉnh/thành phố.");
        setLoading(false);
      });
  }, []);

  // Fetch Quận/Huyện khi tỉnh/thành thay đổi
  useEffect(() => {
    if (selectedProvince) {
      setLoading(true);
      axios
        .get(`https://main.doapp.vn/api/place/vn/district/${selectedProvince}`)
        .then((response) => {
          setDistricts(response?.data?.data || []);
          setSelectedDistrict(""); // Reset selected district
          setWards([]); // Reset wards
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching districts:", error);
          setError("Có lỗi xảy ra khi lấy danh sách quận/huyện.");
          setLoading(false);
        });
    } else {
      setDistricts([]);
      setWards([]);
    }
  }, [selectedProvince]);

  // Fetch Xã/Phường khi quận/huyện thay đổi
  useEffect(() => {
    if (selectedDistrict) {
      setLoading(true);
      axios
        .get(`https://main.doapp.vn/api/place/vn/wards/${selectedDistrict}`)
        .then((response) => {
          setWards(response?.data?.data || []);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching wards:", error);
          setError("Có lỗi xảy ra khi lấy danh sách xã/phường.");
          setLoading(false);
        });
    } else {
      setWards([]);
    }
  }, [selectedDistrict]);

  // Fetch Tỉnh/Thành
  useEffect(() => {
    setLoading(true);
    axios
      .get("https://main.doapp.vn/api/place/vn/province")
      .then((response) => {
        const provinces = response?.data?.data || [];
        provinces.sort((a, b) => {
          if (a.id === 1) return -1; // Hà Nội first
          if (b.id === 1) return 1;
          if (a.id === 79) return -1; // Hồ Chí Minh second
          if (b.id === 79) return 1;
          return 0;
        });
        setProvinces2(provinces);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching provinces:", error);
        setError("Có lỗi xảy ra khi lấy danh sách tỉnh/thành phố.");
        setLoading(false);
      });
  }, []);

  // Fetch Quận/Huyện khi tỉnh/thành thay đổi
  useEffect(() => {
    if (selectedProvince2) {
      setLoading(true);
      axios
        .get(`https://main.doapp.vn/api/place/vn/district/${selectedProvince2}`)
        .then((response) => {
          setDistricts2(response?.data?.data || []);
          setSelectedDistrict2(""); // Reset selected district
          setWards2([]); // Reset wards
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching districts:", error);
          setError("Có lỗi xảy ra khi lấy danh sách quận/huyện.");
          setLoading(false);
        });
    } else {
      setDistricts2([]);
      setWards2([]);
    }
  }, [selectedProvince2]);

  // Fetch Xã/Phường khi quận/huyện thay đổi
  useEffect(() => {
    if (selectedDistrict2) {
      setLoading(true);
      axios
        .get(`https://main.doapp.vn/api/place/vn/wards/${selectedDistrict2}`)
        .then((response) => {
          setWards2(response?.data?.data || []);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching wards:", error);
          setError("Có lỗi xảy ra khi lấy danh sách xã/phường.");
          setLoading(false);
        });
    } else {
      setWards2([]);
    }
  }, [selectedDistrict2]);

  const handleFileChange = (event) => {
    // const file = event.target.files[0];
    const { name, files } = event.target;
    const file = files[0];
    if (file && file.size <= 10 * 1024 * 1024) {
      const formData = new FormData();
      formData.append("image", file);
      console.log("response", formData);
      axios
        .post(
          `https://api.comnieuthienly.vn/api/upload_v2/files?type=chat`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          console.log("response", response);
          setFormData((prevState) => ({
            ...prevState,
            [name]: response?.data?.data.image_url,
          }));
          // setFormData(response.data.data.image_url);
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    } else {
      alert("File quá lớn. Vui lòng chọn ảnh có kích thước tối đa 10 MB.");
    }
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get("https://api.comnieuthienly.vn/api/form-cv")
      .then((response) => {
        const data = response?.data?.data;
        setPositions(data?.positions || []);
        setAreas(data?.areas || []);
        console.log("data", data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
        setError("Có lỗi xảy ra khi lấy dữ liệu.");
        setLoading(false);
      });
  }, []);

  const fetchDataCVInfo = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://api.comnieuthienly.vn/api/cv-info/${id}`
      );
      setFormData(response.data.data || {}); // Cập nhật state với dữ liệu trả về từ API
    } catch (err) {
      console.error("Error fetching data:", err);
      setTimeout(() => {
        setError("");
      }, 2000);
      setError("Có lỗi xảy ra khi lấy dữ liệu.");
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchDataCVInfo(); // Gọi api khi component mount
  }, [fetchDataCVInfo]);

  return (
    <div className="container mx-auto">
      <div className="flex flex-col items-center justify-center mb-8">
        <div className="banner-img card-banner"></div>
        <div className="wrap-content" style={{ marginBottom: 0 }}>
          <div className="line"></div>
          <div className="text">
            <span className="text-heading">
              CUNG CẤP THÔNG TIN NHÂN VIÊN MỚI
            </span>
            <div className="line-top"></div>
            <p className="text-requied">* Chỉ ra những câu hỏi bắt buộc</p>
          </div>
        </div>
        <div className="wrap-content" style={{ marginBottom: 0 }}>
          <div
            className="line"
            style={{ padding: "12px 24px", height: "auto" }}
          >
            <span
              className="text-content"
              style={{ color: "rgb(0, 0, 0)", fontSize: "12pt" }}
            >
              THÔNG TIN CÁ NHÂN
            </span>
          </div>
          <div className="text-content" style={{ marginTop: "50px" }}>
          Các thông tin bạn cung cấp làm cơ sở để lưu trữ dữ liệu nhân sự, do đó, hãy nhập chính xác nhé.
          </div>
        </div>
        <div className="wrap-card">
          <form className="flex flex-col gap-4 mt-6" onSubmit={handleSubmit}>
            {/* Họ và tên */}
            <div className="form-group">
              <label className="text-style display--block" htmlFor="name">
                Họ Và Tên (Viết hoa chữ cái đầu. Ví dụ: Nguyễn Văn A)
                <span className="style-required" aria-label="Required question">
                  *
                </span>
              </label>
              <input
                type="text"
                name="name"
                required
                value={formData?.name}
                onChange={handleChange}
                className="form-input"
                placeholder="Nhập họ và tên"
              />
            </div>

            {/* Ngày tháng năm sinh */}
            <div className="form-group">
              <label className="text-style display--block" htmlFor="dob">
                NGÀY, THÁNG, NĂM SINH
                <span className="style-required" aria-label="Required question">
                  {" "}
                  *
                </span>
              </label>
              <input
                type="date"
                name="birthday"
                value={formatDateVN(formData?.birthday) || ""}
                onChange={handleChange}
                required
                className="form-input"
              />
            </div>

            {/* Số điện thoại */}
            <div className="form-group">
              <label className="text-style display--block" htmlFor="phone">
                SỐ ĐIỆN THOẠI
                <span className="style-required" aria-label="Required question">
                  {" "}
                  *
                </span>
              </label>
              <input
                type="number"
                name="phone"
                value={formData?.phone}
                onChange={handleChange}
                required
                className="form-input"
                pattern="[0-9]{10}"
                placeholder="Nhập số điện thoại"
              />
            </div>

            {/* Giới tính */}
            <div className="form-group">
              <label className="text-style display--block" htmlFor="sex">
                GIỚI TÍNH
                <span className="style-required" aria-label="Required question">
                  {" "}
                  *
                </span>
              </label>
              <label className="mr-5 flex items-center">
                <input
                  type="radio"
                  name="sex"
                  value={1}
                  checked={formData.sex == 1}
                  onChange={handleRadioChange}
                  required
                />
                Nam
              </label>
              <label className="mr-5 flex items-center">
                <input
                  type="radio"
                  name="sex"
                  value={2}
                  checked={formData.sex == 2}
                  onChange={handleRadioChange}
                  required
                />
                Nữ
              </label>
            </div>
            {/* Email */}
            <div className="form-group">
              <label className="text-style display--block" htmlFor="email">
                EMAIL
                <span className="style-required" aria-label="Required question">
                  {" "}
                  *
                </span>
              </label>
              <input
                type="email"
                name="email"
                value={formData?.email}
                onChange={handleChange}
                required
                className="form-input"
              />
            </div>
            {/* MST */}
            <div className="form-group">
              <label className="text-style display--block" htmlFor="mst">
                MÃ SỐ THUẾ CÁ NHÂN
                <span className="style-required" aria-label="Required question">
                  {" "}
                  *
                </span>
              </label>
              <input
                type="number"
                name="mst"
                value={formData?.mst}
                onChange={handleChange}
                required
                className="form-input"
              />
            </div>

            {/* cmnd */}
            <div className="form-group">
              <label className="text-style display--block" htmlFor="cmnd">
                Số CMND cũ (9 số)
                <span className="style-required" aria-label="Required question">
                  {" "}
                  *
                </span>
              </label>
              <input
                type="number"
                name="cmnd"
                value={formData?.cmnd}
                onChange={handleChange}
                required
                className="form-input"
              />
            </div>

            {/* cmnd mới*/}
            <div className="form-group">
              <label className="text-style display--block" htmlFor="cccd">
                Căn Cước Công Dân (12 số)
                <span className="style-required" aria-label="Required question">
                  {" "}
                  *
                </span>
              </label>
              <input
                type="number"
                name="cccd"
                value={formData?.cccd}
                onChange={handleChange}
                required
                className="form-input"
              />
            </div>

            {/* Ngày cấp */}
            <div className="form-group">
              <label className="text-style display--block" htmlFor="date_range">
                NGÀY CẤP
                <span className="style-required" aria-label="Required question">
                  {" "}
                  *
                </span>
              </label>
              <input
                type="date"
                name="date_range"
                value={formatDateVN(formData?.date_range)}
                onChange={handleChange}
                required
                className="form-input"
              />
            </div>

            {/* Nơi cấp */}
            <div className="form-group">
              <label className="text-style display--block" htmlFor="issued_by">
                NƠI CẤP
                <span className="style-required" aria-label="Required question">
                  {" "}
                  *
                </span>
              </label>
              <input
                type="text"
                name="issued_by"
                value={formData?.issued_by}
                onChange={handleChange}
                required
                className="form-input"
              />
            </div>

            {/* Địa chỉ thường trú */}
            <div className="form-group">
              <label
                className="text-style display--block"
                htmlFor="permanent_address"
              >
                ĐỊA CHỈ THƯỜNG TRÚ (KHÔNG VIẾT TẮT & GHI NHƯ CMND/CCCD)
                <span className="style-required" aria-label="Required question">
                  {" "}
                  *
                </span>
              </label>
              {!show2 && (
                <textarea
                  type="text"
                  name="permanent_address"
                  onClick={() => {
                    setShow2(!show2);
                  }}
                  value={formData?.permanent_address}
                  onChange={handleChange}
                  required
                  className="form-input"
                />
              )}
              {show2 && (
                <>
                  <div className="form-group">
                    <select
                      id="province2"
                      class="form-input"
                      name="province2"
                      value={selectedProvince2}
                      onChange={(e) => setSelectedProvince2(e.target.value)}
                      required
                    >
                      <option value="">Chọn Tỉnh/Thành</option>
                      {provinces2.map((province) => (
                        <option key={province.id} value={province.id}>
                          {province.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Quận/Huyện */}
                  <div className="form-group">
                    <select
                      id="district2"
                      class="form-input"
                      name="district2"
                      value={selectedDistrict2}
                      onChange={(e) => setSelectedDistrict2(e.target.value)}
                      disabled={!selectedProvince2}
                      required
                    >
                      <option value="">Chọn Quận/Huyện</option>
                      {districts2.map((district) => (
                        <option key={district.id} value={district.id}>
                          {district.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Xã/Phường */}
                  <div className="form-group">
                    <select
                      id="ward2"
                      class="form-input"
                      name="ward2"
                      value={selectedWard2}
                      onChange={(e) => setSelectedWard2(e.target.value)}
                      disabled={!selectedDistrict2}
                      required
                    >
                      <option value="">Chọn Xã/Phường</option>
                      {wards2.map((ward) => (
                        <option key={ward.id} value={ward.id}>
                          {ward.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Địa chỉ chi tiết */}
                  <div className="form-group">
                    <label
                      style={{ paddingLeft: "15px" }}
                      htmlFor="detailedAddress2"
                    >
                      Địa chỉ chi tiết
                      <span
                        className="style-required"
                        aria-label="Required question"
                      >
                        {" "}
                        *
                      </span>
                    </label>
                    <textarea
                      type="text"
                      name="detailedAddress2"
                      value={detailedAddress2} // Gắn giá trị của detailedAddress vào input
                      onChange={(e) => {
                        setDetailedAddress2(e.target.value);
                      }} // Sử dụng hàm handleAddressChange để lưu giá trị
                      className="form-input"
                      placeholder="Nhập địa chỉ chi tiết"
                      required
                    />
                  </div>
                </>
              )}
            </div>

            {/* Địa chỉ tạm trú */}
            <div className="form-group">
              <div className="flex flex-row content-start items-baseline">
                <label class="text-style display--block mr-5" for="province">
                  ĐỊA CHỈ TẠM TRÚ
                  <span class="style-required" aria-label="Required question">
                    {" "}
                    *
                  </span>
                </label>
              </div>
              {!show && (
                <textarea
                  onClick={() => {
                    setShow(!show);
                  }}
                  type="text"
                  name="detailedAddress"
                  value={formData.temporary_address} // Gắn giá trị của detailedAddress vào input
                  onChange={handleAddressChange} // Sử dụng hàm handleAddressChange để lưu giá trị
                  className="form-input"
                  placeholder="Nhập địa chỉ tạm trú"
                  required
                />
              )}
              {show && (
                <>
                  <div className="form-group">
                    <select
                      id="province"
                      class="form-input"
                      name="province"
                      value={selectedProvince}
                      onChange={(e) => setSelectedProvince(e.target.value)}
                      required
                    >
                      <option value="">Chọn Tỉnh/Thành</option>
                      {provinces.map((province) => (
                        <option key={province.id} value={province.id}>
                          {province.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <select
                      id="district"
                      class="form-input"
                      name="district"
                      value={selectedDistrict}
                      onChange={(e) => setSelectedDistrict(e.target.value)}
                      disabled={!selectedProvince}
                      required
                    >
                      <option value="">Chọn Quận/Huyện</option>
                      {districts.map((district) => (
                        <option key={district.id} value={district.id}>
                          {district.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <select
                      id="ward"
                      class="form-input"
                      name="ward"
                      value={selectedWard}
                      onChange={(e) => setSelectedWard(e.target.value)}
                      disabled={!selectedDistrict}
                      required
                    >
                      <option value="">Chọn Xã/Phường</option>
                      {wards.map((ward) => (
                        <option key={ward.id} value={ward.id}>
                          {ward.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label
                      style={{ paddingLeft: "15px" }}
                      htmlFor="detailedAddress"
                    >
                      Địa chỉ chi tiết
                      <span
                        className="style-required"
                        aria-label="Required question"
                      >
                        {" "}
                        *
                      </span>
                    </label>
                    <textarea
                      type="text"
                      name="detailedAddress"
                      value={detailedAddress} // Gắn giá trị của detailedAddress vào input
                      onChange={handleAddressChange} // Sử dụng hàm handleAddressChange để lưu giá trị
                      className="form-input"
                      placeholder="Nhập địa chỉ chi tiết"
                      required
                    />
                  </div>
                </>
              )}
            </div>

            {/* Upload ảnh mặt trước cccd */}
            <div className="form-group">
              <label className="text-style display--block" htmlFor="cmnd_front">
                Mặt trước CCCD/CMND
                <span className="style-required" aria-label="Required question">
                  {" "}
                  *
                </span>
              </label>
              <input
                type="file"
                name="cmnd_front"
                accept="image/*"
                onChange={handleFileChange}
                required
                className="form-input"
              />
            </div>

            {/* Upload ảnh mặt sau cccd */}
            <div className="form-group">
              <label
                className="text-style display--block"
                htmlFor="cmnd_back_side"
              >
                Mặt sau CCCD/CMND
                <span className="style-required" aria-label="Required question">
                  {" "}
                  *
                </span>
              </label>
              <input
                type="file"
                name="cmnd_back_side"
                onChange={handleFileChange}
                required
                className="form-input"
              />
            </div>

            {/* Ngày nhận việc */}
            <div className="form-group">
              <label className="text-style display--block" htmlFor="startDay">
                NGÀY NHẬN VIỆC
                <span className="style-required" aria-label="Required question">
                  {" "}
                  *
                </span>
              </label>
              <input
                type="date"
                name="start"
                value={formData.start}
                onChange={handleChange}
                required
                className="form-input"
              />
            </div>
            <p>
              Hệ thống Thiên Lý hiện đang trả lương thông qua tài khoản HD Bank.
              Nếu bạn chưa có, hãy đăng ký thông tin mở tài khoản vào link sau
              nhé:{" "}
              <a
                href="https://forms.gle/yaEaqTdDtVy5y8rB6"
                style={{ color: "blue" }}
              >
                https://forms.gle/yaEaqTdDtVy5y8rB6
              </a>{" "}
              Trong tháng đầu tiên nếu chưa có HD Bank, Thiên Lý sẽ tiến hành
              chuyển lương qua tài khoản hiện có. Bạn vui lòng nhập đủ thông tin
              nhé.
            </p>

            {/* số tk ngân hàng */}
            <div className="form-group">
              <label className="text-style display--block" htmlFor="cmnd">
                Số tài khoản ngân hàng
                <span className="style-required" aria-label="Required question">
                  {" "}
                  *
                </span>
              </label>
              <input
                type="number"
                name="bank_number"
                value={formData?.bank_number}
                onChange={handleChange}
                required
                className="form-input"
              />
            </div>

            {/* cmnd */}
            <div className="form-group">
              <label className="text-style display--block" htmlFor="cmnd">
                Họ và tên chủ tài khoản
                <span className="style-required" aria-label="Required question">
                  {" "}
                  *
                </span>
              </label>
              <input
                type="text"
                name="bank_account_name"
                value={formData?.bank_account_name}
                onChange={(e) => {
                  e.target.value = e.target.value.toUpperCase();
                  handleChange(e);
                }}
                required
                className="form-input"
              />
            </div>

            {/* cmnd */}
            <div className="form-group">
              <label className="text-style display--block" htmlFor="cmnd">
                Ngân hàng thụ hưởng
                <span className="style-required" aria-label="Required question">
                  {" "}
                  *
                </span>
              </label>
              <input
                type="text"
                name="bank_name"
                value={formData?.bank_name}
                onChange={handleChange}
                required
                className="form-input"
              />
            </div>

            {/* Submit Button */}
            <div className="flex flex-row content-start items-center">
              <button style={{ marginRight: "25px" }}>
                <Link to={"/danh-muc-ho-so-nhan-vien"}>Trở lại</Link>
              </button>
              <button
                type="submit"
                style={{ backgroundColor: "#e35522", color: "#fff" }}
              >
                Gửi
              </button>
              {loading && <div class="loader"></div>}
              {error && (
                <div id="errorPopup" className="error-popup">
                  {error}
                </div>
              )}
            </div>
          </form>
        </div>
        <a
          className="style-link flex flex-row items-center justify-center"
          href="https://comnieuthienly.vn/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            style={{
              aspectRatio: "auto 74 / 24",
              width: "200px",
            }}
            src="https://i.imgur.com/nB8LOLM.png"
            alt="img-logo-thien-ly"
          />
        </a>
      </div>
      {/* Loading spinner */}
      <div id="loadingSpinner" className="hidden">
        Đang xử lý...
      </div>

      {/* Error notification */}
      <div id="errorPopup" className="hidden">
        <p>Đã xảy ra lỗi khi gửi dữ liệu. Vui lòng thử lại.</p>
      </div>
    </div>
  );
};

export default ProvideInfoEmployee;
