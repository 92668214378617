import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "../axiosConfig";

const Form = () => {
  const [shifts, setShifts] = useState([]);
  const [sources, setSources] = useState([]);
  const [areas, setAreas] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedWard, setSelectedWard] = useState("");
  const [detailedAddress, setDetailedAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedPositionId, setSelectedPositionId] = useState(null);
  const [positions, setPositions] = useState([]);

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    birthday: "",
    phone: "",
    sex: "",
    contact_address: "",
    shift_id: "",
    brand_source_id: "",
    experience: "",
    note: "",
    application_date: new Date().toLocaleDateString("en-CA", {
      timeZone: "Asia/Ho_Chi_Minh",
    }),
    email: null,
    description: "",
    attachments: [],
    level: "",
    position: "",
    evaluations: [],
    area_ids: [],
    area_id: "",
    shift_ids: [],
  });

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle radio button changes
  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    console.log(value, name);
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getAllAddress = () => {
    const provinceText =
      document.querySelector("#province option:checked")?.text || "";
    const districtText =
      document.querySelector("#district option:checked")?.text || "";
    const wardText = document.querySelector("#ward option:checked")?.text || "";
    const allAddress = `${detailedAddress}, ${wardText}, ${districtText}, ${provinceText}`;
    return allAddress;
  };

  // handle form field address changes

  const calculateAge = (birthday) => {
    const birthDate = new Date(birthday);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const handleAddressChange = (e) => {
    setDetailedAddress(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // kiểm tra nhân viên có đủ 18 tuổi không dựa vào thông tin birthday và ngày hiện tại

    const age = calculateAge(formData.birthday);

    if (age < 18) {
      alert(
        "Bạn chưa đủ 18 tuổi, chờ đủ tuổi bạn có thể ứng tuyển nhé !, Xin cảm ơn."
      );
      setLoading(false);
      return;
    }

    const updatedFormData = {
      ...formData,
      contact_address: getAllAddress(),
      recuitment_position_id: selectedPositionId,
    };

    console.log("Updated form data:", updatedFormData);

    if ((formData.evaluations ?? []).length != 0) {
      const hasSelection = (formData.evaluations ?? []).some((evaluation) =>
        evaluation.list.some((item) => item.is_selected === true)
      );

      if (!hasSelection) {
        alert("Vui lòng chọn ít nhất một mục trong phần đánh giá");
        setLoading(false);
        return;
      }
    }

    if (formData.area_id == "") {
      alert("Vui lòng chọn ít nhất một chi nhánh");
      setLoading(false);
      return;
    }

    if ((formData.shift_ids ?? []).length == 0) {
      alert("Vui lòng chọn ít nhất một ca làm việc của vị trí ứng tuyển");
      setLoading(false);
      return;
    }

    console.log("Updated form data:", updatedFormData);

    axios
      .post("https://api.comnieuthienly.vn/api/form-cv-nh", {
        ...updatedFormData,
      })
      .then((response) => {
        setLoading(false);
        setTimeout(() => setError(null), 90000);
        // Redirect to result page
        navigate("/success");
        console.log("Response:", response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
        setError("Có lỗi xảy ra khi gửi dữ liệu.");
        setTimeout(() => setError(null), 3000); // Hide error message after 3 seconds
      });
  };

  // Fetch Tỉnh/Thành
  useEffect(() => {
    setLoading(true);
    axios
      .get("https://main.doapp.vn/api/place/vn/province")
      .then((response) => {
        const provinces = response?.data?.data || [];
        provinces.sort((a, b) => {
          if (a.id === 1) return -1; // Hà Nội first
          if (b.id === 1) return 1;
          if (a.id === 79) return -1; // Hồ Chí Minh second
          if (b.id === 79) return 1;
          return 0;
        });
        setProvinces(provinces);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching provinces:", error);
        setError("Có lỗi xảy ra khi lấy danh sách tỉnh/thành phố.");
        setLoading(false);
      });
  }, []);

  // Fetch Quận/Huyện khi tỉnh/thành thay đổi
  useEffect(() => {
    if (selectedProvince) {
      setLoading(true);
      axios
        .get(`https://main.doapp.vn/api/place/vn/district/${selectedProvince}`)
        .then((response) => {
          setDistricts(response?.data?.data || []);
          setSelectedDistrict(""); // Reset selected district
          setWards([]); // Reset wards
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching districts:", error);
          setError("Có lỗi xảy ra khi lấy danh sách quận/huyện.");
          setLoading(false);
        });
    } else {
      setDistricts([]);
      setWards([]);
    }
  }, [selectedProvince]);

  const convertGender = (gender) => {
    if (gender === "Khác") {
      return 0;
    }
    if (gender === "Nam") {
      return 1;
    }
    if (gender === "Nữ") {
      return 2;
    }
  };

  // Fetch Xã/Phường khi quận/huyện thay đổi
  useEffect(() => {
    if (selectedDistrict) {
      setLoading(true);
      axios
        .get(`https://main.doapp.vn/api/place/vn/wards/${selectedDistrict}`)
        .then((response) => {
          setWards(response?.data?.data || []);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching wards:", error);
          setError("Có lỗi xảy ra khi lấy danh sách xã/phường.");
          setLoading(false);
        });
    } else {
      setWards([]);
    }
  }, [selectedDistrict]);

  useEffect(() => {
    setLoading(true);
    axios
      .get("https://api.comnieuthienly.vn/api/form-cv")
      .then((response) => {
        const data = response?.data?.data;
        setPositions(data?.positions || []);
        setSources(data?.brand_sources || []);
        setAreas(data?.areas || []);
        console.log("data", data.areas);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
        setTimeout(() => {
          setError("");
        }, 2000);
        setError("Có lỗi xảy ra khi lấy dữ liệu.");
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    console.log("areas", areas);
  }, [areas]);

  return (
    <div className="container mx-auto">
      <div className="flex flex-col items-center justify-center mb-8">
        <div className="banner-img card-banner"></div>
        <div
          className="wrap-content p-full flex flex-col items-center justify-center"
          style={{ marginBottom: 0 }}
        >
          <div className="line"></div>
          <div className="text">
            <span className="text-heading">
              Xin chào Bạn, Đây là mẫu đơn ứng tuyển dành cho các vị trí trong
              nhà hàng "CƠM NIÊU THIÊN LÝ"
            </span>
            <div className="line-top"></div>
            <p className="text-requied">* Chỉ ra những câu hỏi bắt buộc</p>
          </div>
        </div>
        <div className="wrap-content" style={{ marginBottom: 0 }}>
          <div
            className="line"
            style={{ padding: "12px 24px", height: "auto" }}
          >
            <span
              className="text-content"
              style={{ color: "rgb(0, 0, 0)", fontSize: "12pt" }}
            >
              CÁC BẠN CLICK VÀO ĐIỀN THÔNG TIN PHÍA DƯỚI NHÉ !
            </span>
          </div>
          <div className="text-content mt--50">
            P.Nhân sự sẽ liên hệ trao đổi công việc và sắp xếp lịch phỏng vấn
            nếu thông tin các bạn phù hợp nè ^^
          </div>
        </div>
        <div className="wrap-card">
          <form className="flex flex-col gap-4 mt-6" onSubmit={handleSubmit}>
            {/* Họ và tên */}
            <div className="form-group">
              <label className="text-style display--block" htmlFor="fullName">
                1. HỌ VÀ TÊN
                <span className="style-required" aria-label="Required question">
                  *
                </span>
              </label>
              <input
                type="text"
                name="name"
                required
                value={formData.name}
                onChange={handleChange}
                className="form-input"
                placeholder="Nhập họ và tên"
              />
            </div>
            {/* Ngày tháng năm sinh */}
            <div className="form-group">
              <label className="text-style display--block" htmlFor="dob">
                2. NGÀY, THÁNG, NĂM SINH
                <span className="style-required" aria-label="Required question">
                  {" "}
                  *
                </span>
              </label>
              <input
                type="date"
                name="birthday"
                value={formData.birthday}
                onChange={handleChange}
                required
                className="form-input"
              />
            </div>

            {/* Số điện thoại */}
            <div className="form-group">
              <label
                className="text-style display--block"
                htmlFor="phoneNumber"
              >
                3. SỐ ĐIỆN THOẠI
                <span className="style-required" aria-label="Required question">
                  {" "}
                  *
                </span>
              </label>
              <input
                type="number"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
                className="form-input"
                pattern="[0-9]{10}"
                placeholder="Nhập số điện thoại"
              />
            </div>

            {/* Email */}
            <div className="form-group">
              <label className="text-style display--block" htmlFor="email">
                3. EMAIL
                <span className="style-required" aria-label="Required question">
                  {" "}
                  *
                </span>
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="form-input"
                placeholder="Nhập email"
              />
            </div>

            {/* Giới tính */}
            <div className="form-group">
              <label className="text-style display--block" htmlFor="gender">
                4. GIỚI TÍNH
                <span className="style-required" aria-label="Required question">
                  {" "}
                  *
                </span>
              </label>
              <label className="mr-5 mb-2 flex items-center">
                <input
                  type="radio"
                  name="sex"
                  value={convertGender("Nam")}
                  checked={formData.sex === "1"}
                  onChange={handleRadioChange}
                  required
                />
                Nam
              </label>
              <label className="mr-5 mb-2 flex items-center">
                <input
                  type="radio"
                  name="sex"
                  value={convertGender("Nữ")}
                  checked={formData.sex === "2"}
                  onChange={handleRadioChange}
                  required
                />
                Nữ
              </label>
            </div>
            <div className="form-group">
              <label class="text-style display--block" for="province">
                5. ĐỊA CHỈ ĐANG CƯ TRÚ
                <span class="style-required" aria-label="Required question">
                  {" "}
                  *
                </span>
              </label>
              <select
                id="province"
                class="form-input"
                name="province"
                value={selectedProvince}
                onChange={(e) => setSelectedProvince(e.target.value)}
                required
              >
                <option value="">Chọn Tỉnh/Thành</option>
                {provinces.map((province) => (
                  <option key={province.id} value={province.id}>
                    {province.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Quận/Huyện */}
            <div className="form-group">
              <select
                id="district"
                class="form-input"
                name="district"
                value={selectedDistrict}
                onChange={(e) => setSelectedDistrict(e.target.value)}
                disabled={!selectedProvince}
                required
              >
                <option value="">Chọn Quận/Huyện</option>
                {districts.map((district) => (
                  <option key={district.id} value={district.id}>
                    {district.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Xã/Phường */}
            <div className="form-group">
              <select
                id="ward"
                class="form-input"
                name="ward"
                value={selectedWard}
                onChange={(e) => setSelectedWard(e.target.value)}
                disabled={!selectedDistrict}
                required
              >
                <option value="">Chọn Xã/Phường</option>
                {wards.map((ward) => (
                  <option key={ward.id} value={ward.id}>
                    {ward.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label style={{ paddingLeft: "15px" }} htmlFor="detailedAddress">
                Địa chỉ chi tiết
                <span className="style-required" aria-label="Required question">
                  {" "}
                  *
                </span>
              </label>
              <div
                style={{
                  height: 10,
                }}
              ></div>
              <textarea
                type="text"
                name="detailedAddress"
                value={detailedAddress} // Gắn giá trị của detailedAddress vào input
                onChange={handleAddressChange} // Sử dụng hàm handleAddressChange để lưu giá trị
                className="form-input"
                placeholder="Nhập địa chỉ chi tiết"
                required
              />
            </div>

            {/* Vị trí ứng tuyển */}
            <div id="positionContainer" className="form-group">
              <label className="text-style display--block">
                6. CHỨC VỤ
                <span className="style-required" aria-label="Required question">
                  *
                </span>
              </label>
              {positions?.map((item) => (
                <>
                  <label key={item.id} className="mr-5 flex items-center">
                    <input
                      type="radio"
                      name="position"
                      value={item.name}
                      checked={selectedPositionId == item.id}
                      onChange={(e) => {
                        // handleRadioChange(e);
                        setSelectedPositionId(item.id);
                        setFormData({
                          ...formData,
                          shift_id: "",
                          shift_ids: [],
                          position: item.name,
                          evaluations: item.review_configs?.evaluations,
                        });
                      }}
                      required
                    />
                    {item.name}
                  </label>
                  <div style={{ height: 10 }}></div>
                  {selectedPositionId === item.id && (
                    <div style={{ marginLeft: "20px" }}>
                      {(
                        positions.find((pos) => pos.id === selectedPositionId)
                          ?.shifts ?? []
                      ).map((shift) => (
                        <label
                          key={shift.id}
                          className="mr-5 mb-2 flex items-center"
                        >
                          <input
                            type="checkbox"
                            name="shift_id"
                            value={shift.id}
                            checked={formData.shift_ids?.includes(shift.id)}
                            onChange={(e) => {
                              if (formData.shift_ids?.includes(shift.id)) {
                                setFormData({
                                  ...formData,
                                  shift_ids: formData.shift_ids.filter(
                                    (id) => id !== shift.id
                                  ),
                                });
                              } else {
                                setFormData({
                                  ...formData,
                                  shift_ids: [...formData.shift_ids, shift.id],
                                });
                              }
                            }}
                          />
                          <div style={{ width: "5px" }}></div>
                          {shift.name} ({shift.start_work_hour}:
                          {shift.start_work_minute} - {shift.end_work_hour}:
                          {shift.end_work_minute})
                        </label>
                      ))}
                    </div>
                  )}
                </>
              ))}
            </div>
            {positions?.find((pos) => pos.id === selectedPositionId)
              ?.description && (
              <div
                id="positionContainer"
                className="form-group"
                style={{ marginLeft: 15 }}
              >
                <label
                  dangerouslySetInnerHTML={{
                    __html: positions?.find(
                      (pos) => pos.id === selectedPositionId
                    ).description,
                  }}
                />
              </div>
            )}

            {(formData?.evaluations ?? []).length > 0 && (
              <div
                id="positionContainer"
                className="form-group"
                style={{ marginLeft: 5, fontSize: "16px" }}
              >
                {
                  <ul>
                    {formData?.evaluations.map((evaluation, index) => {
                      return (
                        <div key={evaluation.id} style={{ marginTop: 10 }}>
                          <b
                            style={{
                              marginBottom: "15px",
                              fontWeight: "500",
                              marginBottom: 10,
                            }}
                          >
                            {index + 1}. {evaluation.name}
                          </b>
                          <div style={{ height: 10 }}> </div>
                          {(evaluation.list ?? []).map((item) => {
                            return (
                              <label
                                key={item.id}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: 5,
                                }}
                              >
                                <input
                                  type="checkbox"
                                  name={`position-${evaluation.name}`}
                                  style={{ marginRight: "5px" }}
                                  checked={item.is_selected} // Bind the checked attribute to the item's is_selected state
                                  onChange={(e) => {
                                    if (evaluation.multiple) {
                                      setFormData({
                                        ...formData,
                                        evaluations: formData.evaluations.map(
                                          (ev) => {
                                            if (ev.name === evaluation.name) {
                                              return {
                                                ...ev,
                                                list: ev.list.map((li) => {
                                                  if (li.name === item.name) {
                                                    // Toggle is_selected for multiple selection
                                                    return {
                                                      ...li,
                                                      is_selected:
                                                        !li.is_selected,
                                                    };
                                                  }
                                                  return li;
                                                }),
                                              };
                                            }
                                            return ev;
                                          }
                                        ),
                                      });
                                    } else {
                                      setFormData({
                                        ...formData,
                                        evaluations: formData.evaluations.map(
                                          (ev) => {
                                            if (ev.name === evaluation.name) {
                                              return {
                                                ...ev,
                                                list: ev.list.map((li) => {
                                                  if (li.name === item.name) {
                                                    // Set is_selected to true for the selected item
                                                    return {
                                                      ...li,
                                                      is_selected: true,
                                                    };
                                                  }
                                                  // Set is_selected to false for all other items
                                                  return {
                                                    ...li,
                                                    is_selected: false,
                                                  };
                                                }),
                                              };
                                            }
                                            return ev;
                                          }
                                        ),
                                      });
                                    }
                                  }}
                                />
                                {item.name}
                              </label>
                            );
                          })}
                        </div>
                      );
                    })}
                  </ul>
                }
              </div>
            )}

            {/* Bạn biết đến hệ thống cơm niêu từ nguồn nào */}
            <div id="sourcesContainer" className="form-group">
              <label className="text-style display--block">
                7. BẠN BIẾT ĐẾN HỆ THỐNG CƠM NIÊU THIÊN LÝ TỪ NGUỒN NÀO?
                <span className="style-required" aria-label="Required question">
                  {" "}
                  *
                </span>
              </label>
              {sources.map((item) => (
                <label key={item.id} className="mr-5 mb-2 flex items-center">
                  <input
                    type="radio"
                    name="brand_source_id"
                    value={item.id}
                    required
                    checked={formData.brand_source_id == item.id}
                    onChange={handleRadioChange}
                  />
                  {item.name}
                </label>
              ))}
            </div>
            <div id="areasContainer" className="form-group">
              <label className="text-style display--block">
                8. CHI NHÁNH ƯU TIÊN ỨNG TUYỂN?
                <span className="style-required" aria-label="Required question">
                  *
                </span>
              </label>
              {selectedPositionId == null
                ? areas.map((item) => (
                    <label
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 10,
                      }}
                      key={item.id}
                    >
                      <input
                        type="checkbox"
                        name="area_ids"
                        className="mr-2"
                        checked={formData.area_id == item.id}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            area_id: item.id,
                          });
                        }}
                      />
                      {item.address}
                    </label>
                  ))
                : areas
                    .filter((e) =>
                      (e.position_ids ?? []).includes(selectedPositionId)
                    )
                    .map((item) => (
                      <label
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: 10,
                        }}
                        key={item.id}
                      >
                        <input
                          type="checkbox"
                          name="area_ids"
                          className="mr-2"
                          checked={formData.area_id == item.id}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              area_id: item.id,
                            });
                          }}
                        />
                        {item.address}
                      </label>
                    ))}
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 10,
                }}
                key={'Các chi nhánh khác'}
              >
                <input
                  type="checkbox"
                  name="area_ids"
                  className="mr-2"
                  checked={formData.area_id == -1}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      area_id: -1,
                    });
                  }}
                />
                {'Các chi nhánh khác'}
              </label>
            </div>
            {/* {
              <div id="areasContainer" className="form-group">
                <label className="text-style display--block">
                  9. CHI NHÁNH MUỐN ỨNG TUYỂN KHÁC?
                </label>
                {areas
                  .filter((e) => e.id != formData.area_id)
                  .map((item) => (
                    <label
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 10,
                      }}
                      key={item.id}
                    >
                      <input
                        type="checkbox"
                        name="area_ids"
                        className="mr-2"
                        checked={formData.area_ids.includes(item.id)}
                        onChange={(e) => {
                          setFormData((prevFormData) => {
                            if (prevFormData.area_ids.includes(item.id)) {
                              // Nếu checkbox bị bỏ chọn, loại bỏ item.id khỏi mảng
                              return {
                                ...prevFormData,
                                area_ids: prevFormData.area_ids.filter(
                                  (id) => id !== item.id
                                ),
                              };
                            } else {
                              // Nếu checkbox được chọn, thêm item.id vào mảng
                              return {
                                ...prevFormData,
                                area_ids: [...prevFormData.area_ids, item.id],
                              };
                            }
                          });
                        }}
                      />
                      {item.address}
                    </label>
                  ))}
              </div>
            } */}
            {/* Submit Button */}
            <div className="flex flex-row content-start items-center">
              <button style={{ marginRight: "25px" }}>
                <Link to={"/"}>Trở lại</Link>
              </button>
              <button
                type="submit"
                style={{ backgroundColor: "#e35522", color: "#fff" }}
              >
                Gửi
              </button>
              {loading && (
                <div
                  style={{
                    marginLeft: "50px",
                  }}
                >
                  <div class="loader"></div>
                </div>
              )}
              {error && (
                <div id="errorPopup" className="error-popup">
                  {error}
                </div>
              )}
            </div>
          </form>
        </div>
        <a
          className="style-link flex flex-row items-center justify-center"
          href="https://comnieuthienly.vn/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            style={{
              aspectRatio: "auto 74 / 24",
              width: "200px",
            }}
            src="https://i.imgur.com/nB8LOLM.png"
            alt="img-logo-thien-ly"
          />
        </a>
      </div>
      {/* Loading spinner */}
      <div id="loadingSpinner" className="hidden">
        Đang xử lý...
      </div>

      {/* Error notification */}
      <div id="errorPopup" className="hidden">
        <p>Đã xảy ra lỗi khi gửi dữ liệu. Vui lòng thử lại.</p>
      </div>
    </div>
  );
};

export default Form;
